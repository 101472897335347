@import '../../custom-varaibles';

.heading-background {
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: 50% 50%;

  & h1 {
      padding: 1rem;
      background: rgba($color: $brand-white, $alpha: 0.4);
  }
}
