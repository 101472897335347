@import "../../custom-varaibles";

.social-media-card {
  border-radius: 0;
  padding: 0;

  &--header {
    display: flex;
    align-items: flex-start;
    padding: 1rem 1rem 0.25rem 1rem;

    &-avatar {
      width: 42px;
      height: 42px;
    }

    &-icon {
      width: 18px;
      height: 18px;
    }

    &-content {
      width: 100%;
      padding: 0 0.5rem;
    }
  }

  &--content {
    padding: 0 1rem;

    &-info {
      margin-bottom: 1rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &--footer {
    position: relative;

    &-content {
      position: absolute;
      z-index: 1;
      bottom: 0;
      padding: 0.5rem;
    }
  }
}
