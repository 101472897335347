@import "~bootstrap/scss/bootstrap";
@import "./_fonts.scss";
@import "./custom-varaibles";

html {
  font-size: 18px;
  scroll-padding-top: 2.5rem;
  // margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
}

p {
  font-family: $paragraph-font;
}

a {
  font-family: $header-font;
  font-size: 0.75rem;
}

ul,
ol {
  padding-left: 1rem;
}

ul {
  &.list-style-white {
    & li {
      list-style-type: disc;

      &::before {
        display: none;
      }
    }

    // & li::before {
    //   display: none;
    //   content: "\2022";
    //   color: white;
    //   font-weight: bold;
    //   display: inline-block;
    //   top: -0.05rem;
    //   left: -1rem;
    //   // width: 1em;
    //   // margin-left: -1em;
    // }
  }

  li {
    position: relative;
    list-style-type: none;

    &::before {
      content: "";
      position: absolute;
      top: 0.25rem;
      left: -1.25rem;
      width: 1rem;
      height: 1rem;
      background-image: url("./assets/unorder-list.svg");
      background-repeat: no-repeat;
    }
  }
}

.navbar {
  height: 64px;

  &.navbar--padded {
    padding: 1rem 3rem;
  }

  &.navbar-transparent {
    background-color: transparent;
  }
}

.image-coverfit {
  height: 100%;
  object-fit: cover;
}

.navbar-nav {
  width: 100%;

  &--padded {
    padding-left: 1rem;
  }

  a {
    font-size: 1rem;
  }

  & .nav-left-margin-auto {
    margin-left: auto;
  }

  & .dropdown-menu {
    border: none;
    background-color: $heading-color;
    color: $background-color;
    padding: 0;
    border-radius: 0;

    a {
      text-transform: capitalize;
      padding: 1rem;

      &:not(:last-child) {
        border-bottom: 1px solid $background-color;
      }
    }

    & .dropdown-item {
      color: inherit;

      &:hover {
        background-color: inherit;
      }
    }
  }

  & > .nav-item .nav-link,
  & > .nav-link {
    color: white !important;
  }

  &.mobile-nav {
    height: 100vh;
    width: 90%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: -90%;
    overflow-x: hidden;
    transition: 0.5s;
    background-color: white;

    & .nav-left-margin-auto {
      margin-left: 0;
    }

    &-expand {
      left: 0%;
    }

    & .mobile-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem 1rem;
      background-color: $heading-color;
    }

    & > .nav-item,
    & > .nav-link {
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      border-bottom: 1px solid $stroke-color;
    }

    & > .nav-item .nav-link,
    & > .nav-link {
      color: $heading-color !important;
    }

    & > .nav-link {
      padding: 1rem;
    }
  }
}

.mt-n6 {
  margin-top: -3.6rem;
}

.w-75 {
  width: 75%;
}

.display-5 {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-6 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-7 {
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-8 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-9 {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.2;
}

.line-white {
  border-color: rgba($color: #fff, $alpha: 0.6);
}

.hide {
  display: none !important;
}

.border-none {
  border: none !important;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-default {
  font-size: 1rem;
}

.font-md {
  font-size: 0.9rem;
}

.font-sm {
  font-size: 0.8rem;
}

.font-mono {
  font-family: $mono-font;
}

.font-paragraph-font {
  font-family: $paragraph-font;
}

.font-heading-font {
  font-family: $header-font;
}

.download-link {
  font-size: 1.2rem;
  color: $brand-2;

  &:hover {
    color: $brand-2;
  }
}

.opacity-100 {
  opacity: 1 !important;
}

.text {
  &-brand-1 {
    color: $brand-1;
  }

  &-brand-2 {
    color: $brand-1;
  }
}

.btn {
  &-rounded {
    border-radius: 999px;
  }

  &.btn-highlight {
    color: #fff;
    background-color: $highlight-color;
    border-color: $highlight-color;

    &:hover {
      color: #fff;
      background-color: $highlight-color;
      border-color: $highlight-color;
    }
  }
}

.form-control {
  &.form-control--transparent {
    background-color: transparent;
    color: white;
    border-radius: 0.5rem;

    &:active {
      background-color: white;
      color: $text-color;
    }

    &::-webkit-input-placeholder {
      color: white;
    }

    &:-ms-input-placeholder {
      color: white;
    }

    &::placeholder {
      color: white;
    }
  }
}

.bg {
  &-position {
    &--top-center {
      background-position: top center !important;
    }
  }

  &-highlight-color {
    background-color: $highlight-color;
  }

  &-heading-color {
    background-color: $heading-color;
  }

  &-link-color {
    background-color: $link-color;
  }

  &-text-color {
    background-color: $text-color;
  }

  &-text-heading-color {
    background-color: $text-heading-color;
  }

  &-text-light-color {
    background-color: $text-light-color;
  }

  &-background-color {
    background-color: $background-color;
  }

  &-stroke-color {
    background-color: $stroke-color;
  }

  &-brand-white {
    background-color: $brand-white;
  }
  &-brand-2 {
    background-color: rgba($color: $brand-2, $alpha: $alpha-01);
  }

  &-page-background-1 {
    background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.75) 0%,
        rgba(255, 255, 255, 0.75) 100%
      ),
      url("./assets/page-background-1.png");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-1-variant-2 {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.65) 0%,
        rgba($heading-color, 0.65) 100%
      ),
      url("./assets/page-background-1.png");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-2 {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      url("./assets/page-background-2.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-3 {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.65) 0%,
        rgba($heading-color, 0.65) 100%
      ),
      url("./assets/page-background-3.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-4 {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/page-background-4.png");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-5 {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/page-background-5.png");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-6 {
    background-image: linear-gradient(
        180deg,
        rgba($link-color, 0.8) 0%,
        rgba($link-color, 0.8) 75%
      ),
      url("./assets/page-background-6.png");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-7 {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/page-background-7.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-7-variant-2 {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.9) 0%,
        rgba($heading-color, 0.9) 100%
      ),
      url("./assets/page-background-7.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-8 {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/page-background-8.png");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-9 {
    background-image: linear-gradient(
        180deg,
        rgba($link-color, 0.9) 0%,
        rgba($link-color, 0.9) 100%
      ),
      url("./assets/page-background-9.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-10 {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.65) 0%,
        rgba($heading-color, 0.65) 100%
      ),
      url("./assets/page-background-10.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-11 {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/page-background-11.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-12 {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.9) 0%,
        rgba($heading-color, 0.9) 100%
      ),
      url("./assets/page-background-12.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-13 {
    background-image: linear-gradient(
        180deg,
        rgb(0 40 85 / 34%) 0%,
        rgb(0 40 85 / 74%) 100%
      ),
      url("./assets/page-background-13.jpg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-14 {
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/page-background-14.jpg");

    background-position: center center;
    background-size: cover;
  }
  &-page-background-dream-fest {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/Dreamfest.JPG");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-fdp {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/FDP.jpeg");

    background-position: center center;
    background-size: cover;
  }

  &-page-background-seminar {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/Seminars.JPG");

    background-position: center center;
    background-size: cover;
  }
  &-page-background-cc {
    background-image: linear-gradient(
        180deg,
        rgba($heading-color, 0.6) 0%,
        rgba(0, 0, 0, 0.6) 75%
      ),
      url("./assets/Commerce_Conclave.JPG");

    background-position: center center;
    background-size: cover;
  }

  &-polka {
    &-1 {
      background-color: rgba($color: $brand-white, $alpha: $alpha-02);
      background-image: radial-gradient(
        rgba($color: $brand-1, $alpha: $alpha-02) 1px,
        rgba($color: $brand-white, $alpha: $alpha-02) 1px
      );
      background-size: 20px 20px;
    }

    &-2 {
      background-color: rgba($color: $brand-white, $alpha: $alpha-02);
      background-image: radial-gradient(
          rgba($color: $brand-1, $alpha: $alpha-02) 1px,
          transparent 1px
        ),
        radial-gradient(
          rgba($color: $brand-1, $alpha: $alpha-02) 1px,
          rgba($color: $brand-white, $alpha: $alpha-02) 1px
        );
      background-size: 40px 40px;
      background-position: 0 0, 20px 20px;
    }
  }

  &-diag {
    &-1 {
      background-color: rgba($color: $brand-white, $alpha: $alpha-02);

      background-size: 6px 6px;
      background-image: repeating-linear-gradient(
        45deg,
        rgba($color: $brand-2, $alpha: $alpha-02) 0,
        rgba($color: $brand-2, $alpha: $alpha-02) 0.6px,
        rgba($color: $brand-white, $alpha: $alpha-01) 0,
        rgba($color: $brand-white, $alpha: $alpha-01) 50%
      );
    }
  }
}

.heading-text {
  color: $brand-1;
  letter-spacing: 0.05rem;
  position: relative;
  display: inline-block;

  &--underline {
    text-decoration: underline;
  }

  &--styled {
    &-left-bottom {
      &::before {
        content: "";
        width: 30px;
        height: 10px;
        background-color: rgba($color: $brand-2, $alpha: $alpha-03);
        position: absolute;
        top: 70%;
        left: -5%;
      }
    }

    &-left-top {
      &::before {
        content: "";
        width: 30px;
        height: 10px;
        background-color: rgba($color: $brand-2, $alpha: $alpha-03);
        position: absolute;
        top: 15%;
        left: -5%;
      }
    }

    &-right-bottom {
      &::after {
        content: "";
        width: 30px;
        height: 10px;
        background-color: rgba($color: $brand-2, $alpha: $alpha-03);
        position: absolute;
        top: 70%;
        right: -5%;
      }
    }

    &-right-top {
      &::after {
        content: "";
        width: 30px;
        height: 10px;
        background-color: rgba($color: $brand-2, $alpha: $alpha-03);
        position: absolute;
        top: 15%;
        right: -3%;
      }
    }
  }
}

.text {
  &-white {
    &-hover:hover,
    &-active:active {
      color: white;
    }
  }
  &-highlight-color {
    color: $highlight-color;

    &-hover:hover,
    &-active:active {
      color: $highlight-color;
    }
  }

  &-heading-color {
    color: $heading-color;
  }

  &-link-color {
    color: $link-color;
  }

  &-text-color {
    color: $text-color;
  }

  &-text-heading-color {
    color: $text-heading-color;
  }

  &-text-light-color {
    color: $text-light-color;
  }

  &-background-color {
    color: $background-color;
  }

  &-stroke-color {
    color: $stroke-color;
  }

  &-brand-2 {
    color: $brand-2;
  }
  &-brand-1 {
    color: $brand-1;
  }
}

.border {
  &-radius {
    &-lg {
      border-radius: 1rem;
    }
  }
  &-brand-1 {
    &-all {
      &-lg {
        border: 4px solid $brand-1;
      }

      &-md {
        border: 2px solid $brand-1;
      }

      &-sm {
        border: 1px solid $brand-1;
      }
    }

    &-left {
      border-left: 2px solid $brand-1;
      &-lg {
        border-left: 4px solid $brand-1;
      }

      &-md {
        border-left: 2px solid $brand-1;
      }

      &-sm {
        border-left: 1px solid $brand-1;
      }
    }
  }

  &-brand-2 {
    &-all {
      &-lg {
        border: 4px solid $brand-2;
      }

      &-md {
        border: 2px solid $brand-2;
      }

      &-sm {
        border: 1px solid $brand-2;
      }
    }
    &-left {
      border-left: 2px solid $brand-2;
      &-lg {
        border-left: 4px solid $brand-2;
      }

      &-md {
        border-left: 2px solid $brand-2;
      }

      &-sm {
        border-left: 1px solid $brand-2;
      }
    }
  }
}

.list {
  &-no-style {
    list-style: none;
  }
}

.hero {
  width: 100%;
  height: calc(100vh - 90px);
  position: relative;

  &-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;
  }

  &-content {
    width: 100%;
    position: absolute;
    z-index: 30;

    &--bottom-left {
      position: absolute;
      bottom: 0;
      left: 0;

      padding: 1rem;
      margin: 1rem;

      // padding-left: 1rem;
      // padding-bottom: 0.5rem;

      background-color: #002855;
      opacity: 0.8;

      &.large {
        padding-left: 4rem;
        padding-bottom: 1rem;
      }
    }
  }

  &-svg-element {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

// swiper

.swiper-slide {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.swiper-auto-slide-width {
  // use this with slidesPerViewAuto
  & .swiper-slide {
    width: auto;
  }
}

.swiper-navigation-horizontal-container {
  position: absolute;
  width: 100%;

  &--center {
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
  }

  & .swiper-navigation-control {
    position: absolute;
    top: 0;
    z-index: 2;

    &--center---left {
      left: 0;
      transform: translate(-100%, -50%);

      &--large {
        transform: translate(-150%, -50%);
      }
    }

    &--center---right {
      right: 0;
      transform: translate(100%, -50%);

      &--large {
        transform: translate(150%, -50%);
      }
    }
  }
}

.swiper-pagination-container {
  position: absolute;
  z-index: 2;
  width: 100%;

  &--top {
    top: 0;
    transform: translateY(-200%);
  }

  &--bottom {
    bottom: 0;
    transform: translateY(200%);
  }

  &--horizontal {
    display: flex;
    align-items: center;

    &---right {
      justify-content: flex-end;
    }
  }

  & .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: auto 0;
    border-radius: 0;
    background-color: white;
    border: 1px solid $heading-color;
    opacity: 1;

    &:not(:last-child) {
      margin-right: 0.4rem;
    }

    &-active {
      width: 14px;
      height: 14px;
      background-color: $heading-color;

      &.bordered {
        border: 1px solid white;
      }
    }
  }
}
