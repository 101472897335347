@import "../../custom-varaibles";

.trustee-message-card {
  &--image {
    width: 100%;
  }

  &--content {
    padding: 1rem 0;

    & .quote {
      position: relative;
      z-index: 2;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -1rem;
        background-image: url("../../assets/quote-left.svg");
        background-size: contain;
        background-repeat: no-repeat;
        width: 3rem;
        height: 3rem;
        opacity: 0.1;
        z-index: 2;
      }
    }
  }
}
