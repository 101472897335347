@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

@font-face {
  font-family: "Arno Pro";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/ArnoProRegular.otf") format("opentype");
}

@font-face {
  font-family: "Arno Pro";
  font-style: normal;
  font-weight: 600;
  src: url("./assets/fonts/ArnoProSemiBold.otf") format("opentype");
}

@font-face {
  font-family: "Arno Pro";
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/ArnoProBold.otf") format("opentype");
}
