@import "../../custom-varaibles";

.niis-hero {
  // background-image: linear-gradient(
  //     180deg,
  //     rgba(0, 0, 0, 0.21) 0%,
  //     rgba(0, 0, 0, 0.7) 70.49%
  //   ),
  //   url("../../assets/niis-hero-bg.jpg");
  // background-position: 50% 50%;
  // background-size: cover;
  background-color: black;

  &-video {
    opacity: .8;
  }
}

.hero-text {
  color: white;
}

.hero-content {
  overflow: hidden;
  max-width: 700px;

  &--mobile {
    max-width: 350px;
  }
}
