@import "../../custom-varaibles";

.student-feedback-card {
	border: 1px solid $box-border-color;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: stretch;

	&--head {
		// flex-grow: 1;
	}

	&--image {
		width: 100%;
		height: 100%;
	}

	&--title {
		padding: 0.5rem 0;
		text-align: center;
	}

	&--content {
		position: relative;
		z-index: 2;
		padding: 0.5rem 1rem;

		&::before {
			content: "";
			position: absolute;
			left: 1rem;
			top: -0.5rem;
			background-image: url("../../assets/quote-left.svg");
			background-size: contain;
			background-repeat: no-repeat;
			width: 3rem;
			height: 3rem;
			opacity: 0.1;
			z-index: 2;
		}
	}
}
