@import "../../custom-varaibles";

.advisor-mini-card {
  padding: 1rem;

  &--image {
    margin-bottom: 1rem;
  }

  &--name {
    font-weight: 700;
    color: $highlight-color;
    text-align: center;
    font-size: 1.25rem;
  }

  &--designation {
    color: white;
    text-align: center;
    font-size: 1rem;
  }

  &--content {
    color: white;
    text-align: center;
    font-size: 1rem;
  }
}
