@import "../../custom-varaibles";

.infography {
  &-container {
    color: white;
    padding: 7rem 0;
  }

  &-header {
    margin-bottom: 3.5rem;
    text-align: center;
  }

  &-data {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }
}
