@import "../../custom-varaibles";

.elective-card {
  width: 100%;
  position: relative;
  padding: 2rem;

  &--image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 0.3;
  }

  &.overlay-black {
    background-color: black;
  }

  &.overlay-blue {
    background-color: $heading-color;
  }

  &-content {
    position: relative;
    z-index: 4;
    padding: 1rem;

    &--logo {
      width: 3rem;
      height: 4rem;
      margin-bottom: 1rem;
    }
  }

  &.overlay-black &-content {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0.3) 100%
    );
  }

  &.overlay-blue &-content {
    background: linear-gradient(
      180deg,
      rgba($heading-color, 0.8) 0%,
      rgba($heading-color, 0.8) 100%
    );
  }
}
