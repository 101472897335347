@import "../../custom-varaibles";

.skill-development-card{
  &--image {
    width: 100%;
  }

  &--content {
    position: relative;
    z-index: 2;
    padding: 1rem 0;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      background-image: url("../../assets/quote-left.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 3rem;
      height: 3rem;
      opacity: 0.1;
      z-index: 2;
    }
  }
}
