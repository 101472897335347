.card {
	width: 100%;
	// position: relative;
	// box-shadow: 0 0 50px rgba(0, 0, 0, 0.315);
}
.card .card__info {
	// position: absolute;
	right: 0;
	left: 0;
	bottom: 20%;
	display: flex;
	align-items: flex-end;
	padding: 15px 30px;
	color: #fff;
}
.card .card__info span {
	margin-right: 20px;
}
.card .card__info i {
	margin-right: 10px;
}
.card .card__profile {
	background-color: #efefef;
	display: flex;
	align-items: center;
	padding: 20px;
}
.card .card__profile img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	object-position: center;
	object-fit: cover;
	margin-right: 10px;
}
.card .card__profile h2 {
	color: #334454;
	font-size: 1.2rem;
}
.card .card__profile p {
	color: #a1b2bc;
	font-size: 0.8rem;
}
.card .card__profile p b {
	font-style: italic;
}
